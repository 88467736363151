import React, { useEffect, useState } from "react";
import {
  ContactCard,
  HDivider,
  InfoText,
  MlsTitleAmenities,
  SocialMedia,
  formatDate,
  // eslint-disable-next-line
  formatPhoneNumber,
} from "./amenities";
import { Mail, Editbutton, Print } from "@landvoice/icons";
// eslint-disable-next-line
import {
  PreforeclosureStatusTypeEnum,
  PropertyTypeEnum,
} from "../utils/selectionData";
import useAPI from "./UseApi";
import { FaTimesCircle } from "react-icons/fa";
import AddTags from "./addTags";
import AddNotes from "./addNotes";
import PrintOneView from "./PrintOneView";
const PreforeclosureDetailsPage = ({
  // eslint-disable-next-line
  handleOpen,
  open,
  // eslint-disable-next-line
  type = "",
  id,
  showAuction = true,
  isDistressed = false,
  updateListTags = () => {},
  updateListNotes = () => {},
}) => {
  const [notesOpen, setNotesOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [data, setData] = useState({});
  const { get } = useAPI();
  const [currentMLSNotes, setCurrentMLSNotes] = useState("");
  const [showFullNote, setShowFullNote] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (id) {
        const result = await get(
          `/api/${
            isDistressed ? "distressed" : "preforeclosure"
          }/listing/${id}/`
        );
        setData(result);
      }
    };
    if (id && open) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id, open]);

  const handleNotes = ({ note }) => {
    setNotesOpen((cur) => !cur);
    setCurrentMLSNotes(note);
  };

  const updateNotes = (newNotes) => {
    setData({ ...data, notes: newNotes });
    updateListNotes(newNotes);
  };

  const handleTags = ({ id, tags }) => {
    if (!tagsOpen) {
    }
    setTagsOpen((cur) => !cur);
  };

  const updateTagsInListing = (newTagsArray) => {
    setData(
      { ...data, tags: [...newTagsArray] }

      // Return the listing unchanged if it's not the one to update
    );
    updateListTags([...newTagsArray]);
  };
  const printWindow = () => {
    window.print();
  };

  const removeDuplicatesAndMarkOriginals = (contacts) => {
    const uniqueContacts = [];
    const seenNumbers = new Set();

    contacts.forEach((contact) => {
      if (!seenNumbers.has(contact.phone)) {
        seenNumbers.add(contact.phone);
        uniqueContacts.push({ ...contact, star: false });
      } else {
        // Mark the original contact with a star
        const originalIndex = uniqueContacts.findIndex(
          (c) => c.phone === contact.phone
        );
        if (originalIndex !== -1) {
          uniqueContacts[originalIndex].star = true;
        }
      }
    });

    return uniqueContacts;
  };

  const processedContacts = removeDuplicatesAndMarkOriginals(
    data?.contacts || []
  );

  return (
    <>
      {open ? (
        <section className=" fixed  left-0 top-0 right-0 bottom-0 w-screen h-screen z-[10]  justify-center bg-black bg-opacity-75  ">
          <AddNotes
            handleOpen={handleNotes}
            open={notesOpen}
            postTo={`/api/${
              isDistressed ? "distressed" : "preforeclosure"
            }/listing/${id}`}
            notes={currentMLSNotes}
            updateNotes={updateNotes}
          />
          <AddTags
            handleOpen={handleTags}
            open={tagsOpen}
            postTo={`/api/${
              isDistressed ? "distressed" : "preforeclosure"
            }/listing/${id}/tags`}
            tags={data?.tags}
            updateTags={updateTagsInListing}
          />
          <div className=" w-screen h-screen z-0 absolute" onClick={handleOpen}>
            {" "}
          </div>
          <div
            onClick={() => {}}
            className="  lg:right-0  absolute flex-col gap-12 z-[10] h-screen w-screen lg:w-[825px] bg-[#FFFFFF] overflow-hidden"
          >
            <div className=" md:px-12 md:pt-12 px-4 pt-4 flex justify-between mt-16 md:mt-0  print:hidden">
              <div className="flex gap-6 items-center">
                <p className=" text-[28px] text-[#666666]">
                  {`${isDistressed ? "Distressed: " : "Pre-Foreclosure: "}` +
                    id}
                </p>{" "}
                <Print className={"hover:text-[24px]"} onClick={printWindow} />
              </div>
              <FaTimesCircle
                onClick={handleOpen}
                className=" h-8 w-8 text-[#666666] hover:text-[#E2631B]"
              />
            </div>

            {/* print */}
            {data?.id && (
              <section className="hidden print:block">
                {" "}
                <PrintOneView
                  property={data}
                  title={` ${
                    data?.property?.street_address || data?.street_address
                  }, ${data?.property?.city || data?.city}, ${
                    data?.property?.state || data?.state
                  } ${data?.property?.postal_code || data?.postal_code}`}
                  bath={data?.baths || data?.bathrooms}
                  beds={data?.bedrooms}
                  SqrFeet={data?.square_feet || data?.square_footage}
                  lotSize={data?.acres || data?.property_size}
                  buildDate={data?.year_built}
                  type={PropertyTypeEnum["H"]}
                  close={true}
                  isDetails={true}
                  statusDate={data?.status_date}
                  status={PreforeclosureStatusTypeEnum[data?.listing_type]}
                  where={isDistressed ? "Distressed" : "pre-foreclosure"}
                  rate={data?.distressed_level ?? 0}
                />
              </section>
            )}

            {/* content */}
            <div className="w-full z-[10] md:px-12 md:pb-12 px-4 pb-1 h-screen overflow-y-auto overflow-x-hidden  print:hidden">
              <MlsTitleAmenities
                all={false}
                isDistressed={isDistressed}
                distressedLevel={data?.distressed_level}
                title={` ${
                  data?.property?.street_address || data?.street_address
                }, ${data?.property?.city || data?.city}, ${
                  data?.property?.state || data?.state
                } ${data?.property?.postal_code || data?.postal_code}`}
                bath={
                  data?.property_features?.full_bathrooms || data?.bathrooms
                }
                beds={data?.property_features?.bedrooms}
                SqrFeet={
                  data?.property_features?.total_square_footage ||
                  data?.square_footage
                }
                lotSize={data?.property_features?.acres || data?.property_size}
                buildDate={data?.property_features?.year_built}
                type={PropertyTypeEnum[data?.property_features?.property_type]}
                close={true}
                isDetails={true}
                statusDate={data?.status_date}
                status={PreforeclosureStatusTypeEnum[data?.listing_type]}
                mapLink={`https://www.google.com/maps/place/${data?.property?.street_address}+${data?.property?.city}+${data?.property?.state}+${data?.property?.postal_code}
                `}
              />
              <div>
                <div>
                  <HDivider />
                  <div className="flex gap-[40px] flex-wrap items-center py-[auto] my-2">
                    <InfoText
                      title={"Price"}
                      info={
                        data?.list_price || data?.auction_min_bid
                          ? parseFloat(
                              data?.list_price || data?.auction_min_bid
                            )?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                            })
                          : "--"
                      }
                    />

                    <InfoText
                      title={"Published Date"}
                      info={formatDate(
                        data?.date_recorded || data?.publish_date
                      )}
                    />
                    {!isDistressed && (
                      <InfoText
                        title={"Auction Date"}
                        info={formatDate(data?.auction_date)}
                      />
                    )}

                    <div className="h-11 flex flex-col gap-2 justify-center">
                      <p className="text-[14px] leading-[14px] text-[#999999]">
                        {`Landvoice ID: ${data?.id}`}
                      </p>
                      <p className="text-[14px] leading-[14px] text-[#999999]">
                        Date Processed: {formatDate(data?.publish_date)}
                      </p>
                    </div>
                  </div>
                  <HDivider />
                </div>
              </div>
              <div className=" flex flex-col gap-12 mt-6 print:gap-2 print:mt-2">
                <section>
                  <div className="md:max-w-[729px]  flex gap-8 flex-col md:flex-row print:flex-row ">
                    <div className="  w-full ">
                      <div className="flex gap-4 h-7 items-center   w-full">
                        <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                          Notes{" "}
                          {data?.notes?.length > 0 ? (
                            <span className="font-[500] text-[12px] text-[#999999]">
                              Last modified{" "}
                              {formatDate(data?.notes[0]?.modify_date)}
                            </span>
                          ) : (
                            <span className="font-[500] text-[12px] text-[#999999]">
                              no notes were added
                            </span>
                          )}
                        </p>{" "}
                        <div className="h-[2px] w-full   min-w-[25px]   bg-[#EBEBEB]"></div>
                        <div
                          onClick={() => handleNotes({ note: data?.notes })}
                          className="w-[20px] cursor-pointer"
                        >
                          <Editbutton className="text-[20px] w-5 h-5" />
                        </div>
                      </div>
                      {data?.notes?.length > 0 ? (
                        <>
                          <pre
                            className={`text-[12px] leading-[15px] text-[#666666] cursor-pointer font-[Lato] text-wrap  `}
                          >
                            <span
                              className={`${
                                showFullNote ? "wrap text-wrap" : "line-clamp-3"
                              }`}
                            >
                              {data?.notes[0]?.note}{" "}
                            </span>{" "}
                            {data?.notes[0]?.note.length > 195 && (
                              <span
                                className="font-[500] text-[12px] text-[#999999] italic"
                                onClick={() => {
                                  setShowFullNote((cur) => !cur);
                                }}
                              >
                                {showFullNote ? "... see less" : "... see more"}
                              </span>
                            )}
                          </pre>

                          {data?.notes.length > 1 &&
                            data?.notes[0]?.note?.length < 80 && (
                              <pre
                                className={`text-[12px] leading-[15px] text-[#666666] cursor-pointer font-[Lato] text-wrap   `}
                              >
                                <span
                                  className={`${
                                    showFullNote
                                      ? "wrap text-wrap"
                                      : "line-clamp-3"
                                  }`}
                                >
                                  {data?.notes[1]?.note}{" "}
                                </span>{" "}
                                {data?.notes[1]?.note.length > 195 && (
                                  <span
                                    className="font-[500] text-[12px] text-[#999999] italic"
                                    onClick={() => {
                                      setShowFullNote((cur) => !cur);
                                    }}
                                  >
                                    {showFullNote
                                      ? "... see less"
                                      : "... see more"}
                                  </span>
                                )}
                              </pre>
                            )}
                        </>
                      ) : (
                        <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                          No notes added.{" "}
                          <span
                            onClick={handleNotes}
                            className=" underline cursor-pointer"
                          >
                            Click to add notes.
                          </span>
                        </p>
                      )}
                    </div>

                    <div className=" max-w-[260px] ">
                      <div className="flex w-full gap-4 h-7 items-center  ">
                        <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                          Tags{" "}
                        </p>{" "}
                        <div className="h-[2px] w-full    min-w-[200px]   bg-[#EBEBEB]"></div>
                        <div onClick={handleTags} className="w-[20px]">
                          <Editbutton className="text-[20px] w-5 h-5" />
                        </div>
                      </div>
                      {data?.tags?.length ? (
                        <div className=" flex flex-wrap gap-2">
                          {data?.tags?.map((tag, index) => {
                            return (
                              <div className="flex items-center justify-between h-6 min-w-[50px] bg-[#EBEBEB] px-4 gap-1 rounded-[15.56px]">
                                <p className="text-[11px] font-bold text-[#666666] leading-4">
                                  {tag}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                          No tags found.{" "}
                          <span
                            onClick={handleTags}
                            className=" underline cursor-pointer"
                          >
                            Click to add tags.
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </section>

                <section>
                  <div className="max-w-[729px] min-h-[140px]">
                    <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                      <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                        Owner Information{" "}
                        <span className="font-[500] text-[12px] text-[#999999]"></span>
                      </p>{" "}
                      <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
                    </div>
                    <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 max-h-[250px] md:h-[150px] lg:max-h-[60px] print:grid-cols-3">
                      <dir>
                        <div className="min-h-[50px] flex flex-col justify-between">
                          <p className=" font-semibold text-[14px] leading-5 text-[#666666] ">
                            Owners Name
                          </p>
                          {data?.owner_name || data.owner?.first_name ? (
                            <p className="  text-[14px] leading-5 text-[#666666] ">
                              {data?.owner_name ||
                                data?.owner?.full_name ||
                                `${data?.owner?.first_name} ${data?.owner?.last_name}`}
                            </p>
                          ) : (
                            <p className="  text-[14px] leading-5 text-[#999999] italic">
                              No Information found
                            </p>
                          )}
                        </div>
                        <div className="flex mt-4 gap-2">
                          <p className="text-[#999999] text-[12px] leading-[14px] font-semibold ">
                            Socials:
                          </p>
                          <SocialMedia
                            owner={{
                              ...data?.owner_information,
                              ...data?.social_media,
                              email: data?.social_media?.email,
                              where: "details",
                            }}
                          />
                        </div>
                      </dir>
                      <div className="h-[50px] flex flex-col justify-between max-w-[220px]">
                        <p className=" font-semibold text-[14px] leading-5 text-[#666666] ">
                          Owners Address
                        </p>
                        {data?.owner_information?.property?.street_address ||
                        data.owner?.mailing_property?.street_address ? (
                          <p className="  text-[14px] leading-5 text-[#999999] ">
                            {`${
                              data?.owner_information?.property
                                ?.street_address ||
                              data?.owner?.mailing_property?.street_address
                            }, ${
                              data?.owner_information?.property.city ||
                              data?.owner?.mailing_property?.city
                            }, ${
                              data?.owner_information?.property?.state ||
                              data?.owner?.mailing_property?.state
                            } ${
                              data?.owner_information?.property?.postal_code ||
                              data?.owner?.mailing_property?.postal_code
                            }`}{" "}
                          </p>
                        ) : (
                          <p className="  text-[14px] leading-5 text-[#999999] italic">
                            No Owner address found
                          </p>
                        )}
                      </div>
                      <div className="h-[50px] flex flex-col justify-between max-w-[220px]">
                        <p className=" font-semibold text-[14px] leading-5 text-[#666666] ">
                          Owners Email
                        </p>
                        <div className="flex gap-3 items-center">
                          <Mail className="text-[16px]" />{" "}
                          {data?.social_media?.email ? (
                            <p className="  text-[14px] leading-5 text-[#999999] ">
                              {data?.social_media?.email}
                            </p>
                          ) : (
                            <p className="  text-[14px] leading-5 text-[#999999] italic">
                              No email found
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {processedContacts?.length > 0 && (
                  <section>
                    <div className="max-w-[729px]">
                      <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                        <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                          Phone Numbers Found{" "}
                          <span className="font-[500] text-[12px] text-[#999999]"></span>
                        </p>{" "}
                        <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
                      </div>
                      <div
                        className={
                          " grid grid-cols-1 gap-4  sm:grid-cols-2 md:grid-cols-4 max-h-[200px] sm:h-[150px] md:max-h-[60px] print:grid-cols-4 print:h-[50px]"
                        }
                      >
                        {processedContacts?.map((contact, index) => (
                          <ContactCard
                            id={index}
                            name={`${contact?.first_name} ${contact?.last_name}`}
                            number={contact?.phone}
                            dnc={contact?.phone_dnc}
                            type={contact?.phone_type}
                            icon="Owner"
                            hidden={{
                              hidden: contact?.hidden,
                              tell: contact.phone,
                            }}
                            list={"preforeclosure"}
                            property_id={data?.property?.id}
                          />
                        ))}
                      </div>
                    </div>
                  </section>
                )}

                <section>
                  <div className="max-w-[729px">
                    <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                      <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                        Mortgage Information{" "}
                        <span className="font-[500] text-[12px] text-[#999999]"></span>
                      </p>{" "}
                      <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
                    </div>
                    <div className=" flex justify-between gap-3 flex-col min-w-[40px] sm:flex-row ">
                      <div className="text-[#666666]  flex-1">
                        <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]  ">
                          <p className="text-[14px] font-semibold leading-5">
                            Mortgage Holder:
                          </p>
                          <p className="text-[14px] leading-5">
                            {data?.mortgage_holder}
                          </p>
                        </div>
                        <div className="flex justify-between gap-3  min-w-[40px]   ">
                          <p className="text-[14px] font-semibold leading-5">
                            Loan Amount:
                          </p>
                          <p className="text-[14px] leading-5">
                            {parseFloat(data?.loan_amount || 0).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                              }
                            )}
                          </p>
                        </div>
                        {!isDistressed && (
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              County Recorded:
                            </p>
                            <p className="text-[14px] leading-5">
                              {data?.county_recorded}
                            </p>
                          </div>
                        )}
                        {!isDistressed && (
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              Attorney Name:
                            </p>
                            <p className="text-[14px] leading-5">
                              {data?.attorney_name}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className=" flex-1">
                        <div className="flex justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px] font-semibold leading-5 text-[#666666] ">
                            Mortgage Date:
                          </p>
                          <p className="text-[14px] leading-5 text-[#666666] ">
                            {formatDate(data?.mortgage_date)}
                          </p>
                        </div>
                        {isDistressed && (
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]">
                            <p className="text-[14px] font-semibold leading-5">
                              County Recorded:
                            </p>
                            <p className="text-[14px] leading-5">
                              {data?.county_recorded}
                            </p>
                          </div>
                        )}
                        {!isDistressed && (
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Delinquent Amount:
                            </p>
                            <p className="text-[14px] leading-5">
                              {parseFloat(
                                data?.delinquent_amount || 0
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                        )}
                        {!isDistressed && (
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Date Recorded:
                            </p>
                            <p className="text-[14px] leading-5">
                              {formatDate(data?.date_recorded)}
                            </p>
                          </div>
                        )}

                        {!isDistressed && (
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Attorney Phone:
                            </p>
                            <p className="text-[14px] leading-5">
                              {formatPhoneNumber(data?.attorney_phone)}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                {showAuction && (
                  <section>
                    <div className="max-w-[729px">
                      <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                        <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                          Auction Information
                          <span className="font-[500] text-[12px] text-[#999999]"></span>
                        </p>{" "}
                        <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
                      </div>
                      <div className=" flex justify-between gap-3  flex-col min-w-[40px] sm:flex-row ">
                        <div className="text-[#666666]  flex-1">
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]  ">
                            <p className="text-[14px] font-semibold leading-5">
                              Trustee Sale Number:
                            </p>

                            <p className="text-[14px] leading-5">
                              {data?.trustee_sale_number}
                            </p>
                          </div>
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              Auction Location:
                            </p>

                            <p className="text-[14px] leading-5">
                              {data?.auction_location}
                            </p>
                          </div>
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              Min Bid:
                            </p>

                            <p className="text-[14px] leading-5 max-w-[200px] text-right">
                              {parseFloat(
                                data?.auction_min_bid || 0
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                        </div>

                        <div className=" flex-1">
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5 text-[#666666] ">
                              Auction Date:
                            </p>
                            <p className="text-[14px] leading-5 text-[#666666] ">
                              {formatDate(data?.auction_date)}
                            </p>
                          </div>
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Owner Occupied:
                            </p>
                            <p className="text-[14px] leading-5">
                              {data?.owner_information?.owner_occupied
                                ? "yes"
                                : "No"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {/* <section>
                  <div className="max-w-[729px">
                    <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                      <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                        Demographic Information{" "}
                        <span className="font-[500] text-[12px] text-[#999999]"></span>
                      </p>{" "}
                      <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
                    </div>
                    <div className=" flex justify-between gap-3 flex-col min-w-[40px] sm:flex-row ">
                      <div className="text-[#666666]  flex-1">
                        <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]  ">
                          <p className="text-[14px] font-semibold leading-5">
                            Home Value:
                          </p>
                          <p className="text-[14px] leading-5">{""}</p>
                        </div>
                        <div className="flex justify-between gap-3  min-w-[40px]   ">
                          <p className="text-[14px] font-semibold leading-5">
                            Age:
                          </p>
                          <p className="text-[14px] leading-5">{""}</p>
                        </div>
                        {
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              Income:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                        {
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              Marital Status:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                        {
                          <div className="flex justify-between gap-3  min-w-[40px]">
                            <p className="text-[14px] font-semibold leading-5">
                              Gender:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                      </div>

                      <div className=" flex-1">
                        <div className="flex justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px] font-semibold leading-5 text-[#666666] ">
                            Number of Children:
                          </p>
                          <p className="text-[14px] leading-5 text-[#666666] ">
                            {""}
                          </p>
                        </div>
                        {
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]">
                            <p className="text-[14px] font-semibold leading-5">
                              Education:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                        {
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Net Worth:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                        {
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Occupation:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                        {
                          <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                            <p className="text-[14px] font-semibold leading-5">
                              Business Owner:
                            </p>
                            <p className="text-[14px] leading-5">{""}</p>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </section> */}
                {/* <section>
                  <div className="max-w-[729px">
                    <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                      <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                        Ad Text
                        <span className="font-[500] text-[12px] text-[#999999]"></span>
                      </p>{" "}
                      <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
                    </div>

                    <p className="text-[14px] text-[#666666] leading-[21px] print:text-[10px] print:leading-3 ">
                      {data?.ad_text}
                    </p>
                  </div>
                </section> */}

                <section>
                  <div className="max-w-[729px] pb-[100px]">
                    <div className=" flex gap-1  flex-col min-w-[40px] sm:flex-row">
                      <div className="text-[#999999  flex-1">
                        <div className="flex  min-w-[40px] text-[#999999] gap-[2px] ">
                          <p className="text-[14px] leading-[14px]">
                            Landvoice ID:
                          </p>
                          <p className="text-[14px] leading-[14px]">
                            {data?.id}
                          </p>
                        </div>
                      </div>

                      <div className=" flex-1">
                        <div className="flex md:justify-end  min-w-[40px] gap-[2px]">
                          <p className="text-[14px] leading-[14px] text-[#999999] ">
                            Date Processed:
                          </p>
                          <p className="text-[14px] leading-[14px] text-[#999999] ">
                            {formatDate(data?.date_recorded)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default PreforeclosureDetailsPage;
