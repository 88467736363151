import React, { useState, useEffect } from "react";

import { FaPlus } from "react-icons/fa";
import { AddMLS } from "./addMls";
import EditMls from "./editMls";
import useAPI from "../../common/UseApi";

const MlsCredentials = ({ partnerList, mlsCredentials }) => {
  const [mlsOpen, setMlsOpen] = useState(false);
  const [editMlsOpen, setEditMlsOpen] = useState(false);
  const handleAddMLSOpen = () => setMlsOpen((cur) => !cur);

  const [mlsList, setMlsList] = useState([]);
  const [mlsStates, setMlsStates] = useState([]);
  const [customer_mls_cred, setCustomer_mls_cred] = useState("");
  const { get } = useAPI();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get("api/expired/mls");
        const uniqueStates = [...new Set(result.map((item) => item.state))];
        const stateObjects = uniqueStates.map((state) => ({ name: state }));
        setMlsStates(stateObjects);
        setMlsList(result);
      } catch {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditMLSOpen = (customer_mls_id) => {
    setCustomer_mls_cred(customer_mls_id);
    setEditMlsOpen((cur) => !cur);
  };

  return (
    <section className=" min-h-[406px] bg-white rounded-[8px] p-[16px] md:p-[32px] mb-[16px] border-[#EBEBEB] border-[1px]">
      <EditMls
        handleOpen={handleEditMLSOpen}
        open={editMlsOpen}
        partnerList={partnerList}
        customer_mls_cred={customer_mls_cred}
      />
      <AddMLS
        handleOpen={handleAddMLSOpen}
        open={mlsOpen}
        mlsList={mlsList}
        mlsStates={mlsStates}
      />
      <div className="flex justify-between items-center">
        <p className="whitespace-nowrap text-[#666666] text-[20px] sm:text-[28px] leading-[28px] font-[500] mb-[32px]">
          MLS Credentials
        </p>
        {mlsCredentials.length === 0 && (
          <button
            className=" flex justify-between items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4  "
            onClick={handleAddMLSOpen}
          >
            <FaPlus color="white" />
            <p className="text-[14px] font-[500] leading-[28px] text-[#FFFFFF]">
              Add an MLS
            </p>
          </button>
        )}
      </div>

      <div className="flex w-full gap-4 h-7 items-center mb-6 ">
        <div className=" h-7 ">
          <p className=" whitespace-nowrap text-[#666666] font-[700] text-[16px] ">
            Your MLS’s
          </p>
        </div>

        <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>
      </div>

      <p className="text-[14px] font-[400] leading-[20px] text-[#999999] mb-6">
        {mlsCredentials.length === 0
          ? "No MLS's on record. Use the button Above to add MLS "
          : "Here are the MLS’s you have added to your account. You must select an MLS to receive or upload expired data."}
      </p>

      <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>

      {mlsCredentials.map((item) => (
        <div className=" min-h-[76px] w-full flex justify-center items-start md:justify-between  flex-col gap-4  md:flex-row md:items-center p-4 border-b border-[#E0E0E0] hover:bg-[#F5F7F7] ">
          <div>
            <p className=" text-[16px] text-[#666666] font-[600] leading-[20px ">
              {item.name}
            </p>
            <p className="text-[12px] text-[#666666] font-[400] leading-[20px ">
              {item.state}
            </p>
          </div>

          <div
            onClick={() => {
              handleEditMLSOpen(item.customer_mls_id);
            }}
            className=" flex items-center justify-center px-4 py-1 h-9 min-w-[183px] rounded-lg bg-[#E8EDF0]"
          >
            <p className="text-[14px] text-center text-[#666666] font-[400] leading-[20px  font-[lato]">
              Enter/Update Credentials
            </p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default MlsCredentials;
