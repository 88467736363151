// eslint-disable-next-line
import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../components/common/PageLayout";
import Selection from "../components/utils/account/select";
import {
  sortingOptions,
  leadFilterOptions,
  sortListings,
} from "../components/utils/selectionData";

import { Filter } from "@landvoice/icons";
// eslint-disable-next-line react-hooks/exhaustive-deps
import {
  ContactCard,
  // HDivider,
  // InfoText,
  MlsTitleAmenities,
  OwnerContacts,
} from "../components/common/amenities";
import AddNotes from "../components/common/addNotes";
import AddTags from "../components/common/addTags";
import FilterPage from "../components/common/filterPage";
import useAPI from "../components/common/UseApi";
import {
  PropertyTypeEnum,
  StatusTypeEnum,
} from "../components/utils/selectionData";
import Pagination from "../components/common/pagination";
import { useAuth } from "../components/utils/auth/assist/authContext";
import pdfDownload from "../components/common/pdfDownload";
import DetailsPage from "../components/common/detailsPage";
import NoAccessPage from "../components/common/noAccessPage";
import ErrorPopUp from "../components/common/errorPopUp";
import PrintListView from "../components/common/PrintListView";
import PrintOneView from "../components/common/PrintOneView";

//import DetailsPage from "../components/common/detailsPage";

function OldExpired() {
  const [notesOpen, setNotesOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [currentMLS, setCurrentMLS] = useState("");
  const [currentMLSNotes, setCurrentMLSNotes] = useState("");
  const [currentMLSTags, setCurrentMLSTags] = useState([]);
  const [perPage, setPerPage] = useState({ value: "25", id: 0 });
  const [isDownloading, setIsDownloading] = useState(false);
  const [listing, setListing] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [isPrintAll, setIsPrintAll] = useState(false);
  const [isPrintOne, setIsPrintOne] = useState(false);
  const [itemsToPrint, setItemsToPrintsPrintOne] = useState(0);

  const handleNotes = ({ id, notes }) => {
    setNotesOpen((cur) => !cur);
    setCurrentMLS(id);
    setCurrentMLSNotes(notes);
  };
  const handleTags = ({ id, tags }) => {
    if (!tagsOpen) {
      setCurrentMLS(id);
      setCurrentMLSTags(tags);
    }
    setTagsOpen((cur) => !cur);
  };

  const updateTagsInListing = (newTagsArray) => {
    const listingId = currentMLS;
    setListing((currentListings) =>
      currentListings.map((listing) => {
        // Check if this is the listing to update
        if (listing.id === listingId) {
          // Clone the listing to avoid direct mutations and update the tags array
          return { ...listing, tags: [...newTagsArray] };
        }
        // Return the listing unchanged if it's not the one to update
        return listing;
      })
    );
  };

  const updateNotes = (newNotes) => {
    const listingId = currentMLS;
    setListing((currentListings) =>
      currentListings.map((listing) => {
        // Check if this is the listing to update
        if (listing?.id === listingId) {
          // Clone the listing to avoid direct mutations and update the tags array
          return { ...listing, notes: newNotes };
        }
        // Return the listing unchanged if it's not the one to update
        return listing;
      })
    );
  };

  const handleDetals = ({ id, bool }) => {
    setCurrentMLS(id);
    setDetailsOpen((cur) => !cur);
  };

  const handleDownloads = () => {
    setIsDownloading((cur) => !cur);
  };
  const handleFilter = () => setFilterOpen((cur) => !cur);
  const [apiData, setApiData] = useState([]);
  const { get } = useAPI();
  const [link, setLink] = useState(``);
  const [filters, setFilters] = useState([]);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState("Date (Newest)");
  const [showing, setShowing] = useState("Show all ");
  // eslint-disable-next-line
  const [expiredStatuses, setExpiredStatuses] = useState(["X", "W", "C"]);
  const [sort, setSort] = useState("");
  const [leadsFilter, setLeadsFIlter] = useState("");
  const { user } = useAuth();
  const [leadsFilterLoading, setLeadsFIlterLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [listingForPrint, setListingForPrint] = useState([]);
  useEffect(() => {
    setLeadsFIlterLoading(true);
  }, [leadsFilter]);

  useEffect(() => {
    setSortLoading(true);
  }, [sort]);

  useEffect(() => {
    if (currentPage && perPage.value && sort) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, currentPage, perPage.value, sort, leadsFilter]);

  useEffect(() => {
    setSort(sortListings(sorting));
    if (showing === "Show all ") setLeadsFIlter("");

    if (showing === "Show only with email")
      setLeadsFIlter("lead_filter=email-only");

    if (showing === "Show only without phone numbers")
      setLeadsFIlter("lead_filter=no-phones");

    if (showing === "Show only with phone numbers")
      setLeadsFIlter("lead_filter=phones-only");
  }, [sorting, showing]);

  const fetch = async () => {
    if (user?.oldexpireds) {
      const results = await get(
        `/api/oldexpired/listing/?offset=${
          (currentPage - 1) * perPage.value
        }&limit=${
          perPage.value
        }&${link}&${leadsFilter}&${sort}&${expiredStatuses
          .map((element) => `status=${element}`)
          .join("&")}`
      );
      setApiData(results);
      setLeadsFIlterLoading(false);
      setSortLoading(false);
      setListing(results.listings);
      setItemsToPrintsPrintOne(results.total_records);
    }
  };

  const fetchForPrint = async () => {
    if (isDownloading) return;
    if (detailsOpen) {
      window.print();
    }
    if (user?.oldexpireds && !detailsOpen) {
      handleDownloads();
      const results = await get(
        `/api/oldexpired/listing/?offset=${0}&limit=${itemsToPrint}&${link}&${leadsFilter}&${sort}`
      );
      setListingForPrint(results.listings);
    }
  };
  useEffect(() => {
    if (!isDownloading) return;
    if (listingForPrint.length > 0) {
      window.print();
      handleDownloads();
      setIsPrintAll(false);
      setIsPrintOne(false);
    }
    // eslint-disable-next-line
  }, [listingForPrint]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if 'P' is pressed along with 'Control' or 'Command'
      if (
        (event.ctrlKey || event.metaKey) &&
        event.key.toLowerCase() === "p" &&
        detailsOpen === false
      ) {
        event.preventDefault();

        setIsPrintAll(true);
        setTimeout(fetchForPrint, 500);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [listing]);

  const handleWarning = () => {
    setWarningOpen((cur) => !cur);
  };
  useEffect(() => {
    const totalItems = apiData?.total_records;
    const itemsPerPage = perPage.value;

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const pagesArray = [];

    for (let i = 1; i <= totalPages; i++) {
      const pageObject = { name: i.toString() };
      pagesArray.push(pageObject);
    }

    setPageList(pagesArray);
  }, [apiData?.total_records, perPage.value]);

  // Create a ref for the element you want to scroll to
  const sectionRef = useRef(null);

  // Function to scroll to the specific section
  const handleScrollToSection = () => {
    if (sectionRef.current) {
      // Scrolls the section into view
      sectionRef.current.scrollIntoView();
    }
  };

  const removeDuplicatesAndMarkOriginals = (contacts) => {
    const uniqueContacts = [];
    const seenNumbers = new Set();

    contacts.forEach((contact) => {
      if (!seenNumbers.has(contact.phone)) {
        seenNumbers.add(contact.phone);
        uniqueContacts.push({ ...contact, star: false });
      } else {
        // Mark the original contact with a star
        const originalIndex = uniqueContacts.findIndex(
          (c) => c.phone === contact.phone
        );
        if (originalIndex !== -1) {
          uniqueContacts[originalIndex].star = true;
        }
      }
    });

    return uniqueContacts;
  };

  return (
    <section
      id="section-to-scroll"
      ref={sectionRef}
      className="max-h-screen hideScrollBar"
    >
      <PageLayout>
        <ErrorPopUp
          open={warningOpen}
          title={"warning"}
          data={
            "You are currently planning to print more than 1,000 records. (334+ pages) Do you want to edit your search criteria before printing?"
          }
          handleOpen={handleWarning}
          proceed={fetchForPrint}
        />
        <FilterPage
          handleOpen={handleFilter}
          open={filterOpen}
          title={" Old Expired Filters"}
          filter={setFilters}
          params={setLink}
        />
        <AddNotes
          handleOpen={handleNotes}
          open={notesOpen}
          postTo={`/api/expired/listing/${currentMLS}`}
          notes={currentMLSNotes}
          updateNotes={updateNotes}
          where={"oldexpired"}
        />
        <AddTags
          handleOpen={handleTags}
          open={tagsOpen}
          postTo={`/api/expired/listing/${currentMLS}/tags`}
          tags={currentMLSTags}
          updateTags={updateTagsInListing}
        />
        <DetailsPage
          id={currentMLS}
          handleOpen={handleDetals}
          open={detailsOpen}
          isOld={true}
          updateListTags={updateTagsInListing}
          updateListNotes={updateNotes}
        />
        <div className=" print:hidden min-h-[120px] mb-[16px] pt-8 pb-3">
          <div className=" flex justify-start items-baseline gap-3">
            <h1 className=" leading-[57px] font-[600] text-[48px] text-[#666666]  mb-[14px]">
              Old Expired
            </h1>
            <p className="text-[#666666] leading-[19.2px] font-[400] text-[16px]">
              Showing {apiData?.listings?.length || "--"} of{" "}
              {apiData?.total_records || "--"}
            </p>
          </div>
        </div>

        {user?.oldexpireds ? (
          <section>
            <div className="print:hidden flex gap-3 md:justify-between min-h-10 w-full mt-4 flex-wrap">
              <div className=" flex gap-3 flex-wrap">
                <div
                  className=" items-center flex gap-3 px-4 py-[6px] h-10 w-[114px]  rounded-lg border-[1px] border-[#EBEBEB] leading-6 text-[14px] text-[#666666]"
                  onClick={handleFilter}
                >
                  {" "}
                  <Filter />
                  Filters
                </div>
                <div className=" h-10 min-w-[145px]  rounded-lg ">
                  <Selection
                    rounded={"8px"}
                    SelectionColor={false}
                    selecionHeight={"36px"}
                    setIsActive={() => {}}
                    list={leadFilterOptions}
                    initialSelection={0}
                    flowWidth={true}
                    onValueChange={setShowing}
                    isDownload={true}
                    isDoenloading={leadsFilterLoading}
                  />
                </div>
                <div className=" h-10 min-w-[145px]  rounded-lg ">
                  <Selection
                    headding={"Sort By:"}
                    setIsActive={() => {}}
                    list={sortingOptions}
                    initialSelection={2}
                    rounded={"8px"}
                    SelectionColor={false}
                    selecionHeight={"36px"}
                    flowWidth={true}
                    onValueChange={setSorting}
                    isDownload={true}
                    isDoenloading={sortLoading}
                  />
                </div>
              </div>

              <div className=" flex gap-4">
                <div className=" h-10 min-w-[145px]  rounded-lg ">
                  <Selection
                    isFunctional={true}
                    positionLeft={false}
                    headding={"Download/Print"}
                    isDownload={true}
                    isDoenloading={isDownloading}
                    setIsActive={() => {}}
                    list={[
                      {
                        name: "Download As CSV",
                        clickableFunction: () => {
                          handleDownloads();
                          pdfDownload(
                            `/api/oldexpired/listing/csv?offset=${
                              (currentPage - 1) * perPage.value
                            }&limit=${
                              perPage.value
                            }&${link}&${leadsFilter}&${sort}&${expiredStatuses
                              .map((element) => `status=${element}`)
                              .join("&")}`,
                            "oldexpiredlisting",
                            "csv",
                            handleDownloads
                          );
                        },
                      },
                      {
                        name: "Print All Records",
                        clickableFunction: () => {
                          if (apiData?.total_records >= 1000) {
                            setWarningOpen(true);
                          } else {
                            fetchForPrint();
                            setIsPrintAll(true);
                          }
                        },
                      },
                      {
                        name: "Print 1 Record/Page",
                        clickableFunction: () => {
                          if (apiData?.total_records >= 1000) {
                            setWarningOpen(true);
                          } else {
                            fetchForPrint();
                            setIsPrintOne(true);
                          }
                        },
                      },
                    ]}
                    initialSelection={0}
                    rounded={"8px"}
                    SelectionColor={false}
                    check={false}
                    selecionHeight={"36px"}
                    flowWidth={true}
                    onValueChange={() => {}}
                  />
                </div>
              </div>
            </div>

            <div className=" print:hidden min-h-[28px] w-full px-4 flex gap-3 mt-4 flex-wrap">
              <p className=" font-semibold text-[14px] text-[#999999] leading-6 ">
                Active Filters:
              </p>
              {filters.map((filter, index) => (
                <>
                  {Object.entries(filter).map(([key, value]) =>
                    value &&
                    value !== "Any" &&
                    value !== "" &&
                    key !== "tags" &&
                    value !== "searchDatType" ? (
                      <div
                        key={index}
                        className="h-[24px] min-w-[59px] rounded-full bg-[#EBEBEB] py-1 px-2"
                      >
                        <p
                          key={key}
                          className="text-[11px] text-[#666666] font-bold leading-4"
                        >
                          {key}: {value !== undefined ? value : "N/A"}
                        </p>
                      </div>
                    ) : null
                  )}
                </>
              ))}
            </div>
            <section className={`hideScrollBar mt-4 print:mt-0 print:hidden  `}>
              {listing?.map((property, index) => {
                const processedContacts = removeDuplicatesAndMarkOriginals(
                  property?.contacts || []
                );
                return (
                  <div className="break-inside-avoid py-3 px-4 w-full flex flex-col flex-wrap md:flex-row gap-4 border-b-[1px] border-[#ccc] min-h-[242px] rounded-lg">
                    <MlsTitleAmenities
                      openDetails={handleDetals}
                      id={property.id}
                      title={`${property?.property?.street_address}, ${property?.property?.city}, ${property?.property?.state} ${property?.property?.postal_code}`}
                      beds={property?.bedrooms}
                      bath={property?.baths}
                      SqrFeet={property?.square_feet.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2, // You can adjust this based on your requirements
                      })}
                      lotSize={property?.acres}
                      buildDate={property?.year_built}
                      type={PropertyTypeEnum[property?.property_type]}
                      statusDate={property?.status_date}
                      status={StatusTypeEnum[property.status]}
                      mls_number={property?.mls_number}
                      price={
                        property?.list_price
                          ? parseFloat(property?.list_price).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              }
                            )
                          : "--"
                      }
                      dom={property?.days_on_market}
                      notes={property?.notes}
                      tags={property?.tags}
                      hasDom={true}
                      where={"Old Expired"}
                      openNotes={() =>
                        handleNotes({
                          id: property.id,
                          notes: property?.notes,
                        })
                      }
                      openTags={() =>
                        handleTags({ id: property.id, tags: property.tags })
                      }
                      mapLink={`https://www.google.com/maps/place/${property?.property?.street_address}+${property?.property?.city}+${property?.property?.state}+${property?.property?.postal_code}
                  `}
                    />

                    <OwnerContacts
                      owner={{
                        ...property?.owner_information,
                        email: property?.social_media?.email,
                      }}
                    />

                    <div className="lg:flex   flex-col h-full xl:py-6 sm:min-w-[320px] xl:w-[320px]">
                      <p className=" text-[14px] text-[#666666] leading-6 font-semibold mb-4">
                        Contacts ({processedContacts.length})
                      </p>
                      <div className=" w-[calc(100vw-32px)] overflow-scroll xl:w-[380px] hideScrollBar">
                        <div className={`w-[733px] xl:w-[370px]`}>
                          <div className=" grid xl:grid-cols-2 grid-cols-4 gap-3 ">
                            {processedContacts.map(
                              (item, index) =>
                                index <= 3 && (
                                  <ContactCard
                                    name={`${item.first_name} ${item.last_name}`}
                                    number={item.phone}
                                    dnc={item.phone_dnc}
                                    property_id={property?.property?.id}
                                    isStared={item?.star}
                                    list={"oldexpired"}
                                    type={item?.phone_type}
                                    hidden={{
                                      hidden: item?.hidden,
                                      tell: item.phone,
                                    }}
                                  />
                                )
                            )}
                            {/* {property.contacts.length > 3 && (
                              <div className=" h-[60.5px] w-[150px] flex justify-center items-center">
                                <p className=" text-[12px] font-bold  text-#666666] leading-4">
                                  See All {`(${property.contacts.length - 3})`}
                                </p>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
            {pageList.length > 0 && (
              <div className="h-[px] w-full flex     gap-6 items-center justify-between sm:flex-col lg:flex-row">
                <div className="min-w-[93px]">
                  <Selection
                    headding={"per page"}
                    setIsActive={() => {}}
                    list={[
                      {
                        name: "25",
                        clickableFunction: () => {
                          setPerPage({ value: "25", id: 0 });
                        },
                      },
                      {
                        name: "50",
                        clickableFunction: () => {
                          setPerPage({ value: "50", id: 1 });
                        },
                      },
                      {
                        name: "100",
                        clickableFunction: () => {
                          setPerPage({ value: "100", id: 2 });
                        },
                      },
                    ]}
                    initialSelection={perPage.id}
                    rounded={"8px"}
                    SelectionColor={false}
                    selecionHeight={"36px"}
                    flowWidth={true}
                    above={true}
                    onValueChange={() => {}}
                  />
                </div>

                <Pagination
                  pages={pageList}
                  currentPage={currentPage}
                  perPage={perPage}
                  onPageChange={setCurrentPage}
                  link={link}
                  scrollIntoView={handleScrollToSection}
                />
              </div>
            )}
          </section>
        ) : (
          <NoAccessPage product="Old Expireds" />
        )}

        {isPrintAll && !detailsOpen && (
          <section className="  gap-6  print:mt-0 hidden print:flex flex-col ">
            {listingForPrint?.map((property, index) => {
              return (
                <PrintListView
                  property={property}
                  title={`${property?.property?.street_address}, ${property?.property?.city}, ${property?.property?.state} ${property?.property?.postal_code}`}
                  key={"listing " + index}
                  openDetails={handleDetals}
                  id={property.id}
                  beds={property?.bedrooms}
                  bath={property?.baths}
                  SqrFeet={property?.square_feet.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2, // You can adjust this based on your requirements
                  })}
                  lotSize={property?.acres}
                  buildDate={property?.year_built}
                  type={PropertyTypeEnum[property?.property_type]}
                  statusDate={property?.status_date}
                  status={StatusTypeEnum[property.status]}
                  where="Expired"
                  price={parseFloat(property?.list_price).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }
                  )}
                  dom={property?.days_on_market}
                />
              );
            })}
          </section>
        )}

        {isPrintOne && (
          <section className="hidden print:block">
            {listingForPrint.map((property, index) => {
              return (
                <PrintOneView
                  index={index}
                  property={property}
                  title={`${property?.property?.street_address}, ${property?.property?.city}, ${property?.property?.state} ${property?.property?.postal_code}`}
                  key={"listing " + index}
                  openDetails={handleDetals}
                  id={property.id}
                  beds={property?.bedrooms}
                  bath={property?.baths}
                  SqrFeet={property?.square_feet.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2, // You can adjust this based on your requirements
                  })}
                  lotSize={property?.acres}
                  buildDate={property?.year_built}
                  type={PropertyTypeEnum[property?.property_type]}
                  statusDate={property?.status_date}
                  status={StatusTypeEnum[property.status]}
                  where="Expired"
                  price={parseFloat(property?.list_price).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }
                  )}
                  dom={property?.days_on_market}
                />
              );
            })}
          </section>
        )}
      </PageLayout>
    </section>
  );
}

export default OldExpired;
